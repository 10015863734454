<template>
  <Layout>
    <div>
      <el-button
        @click="dialogVisible=true"
      >
        新建应用
      </el-button>
    </div>
    <Hyform
      v-loading="listInfo.loading"
      :form-item-prop="formItemProp"
      :table-data="tableData"
    >
      <template v-slot:option="row">
        <el-button
          @click="EditItem(row.tableRowData)"
        >
          编辑
        </el-button>
      </template>
    </Hyform>
    <Pagination
      :total="listInfo.total"
      :page.sync="formData.page"
      :limit.sync="formData.pageSize"
      @pagination="getList"
    />
    <el-dialog
      :title="subFormData.id ? '编辑应用' : '创建应用'"
      :visible.sync="dialogVisible"
      width="800px"
      :before-close="handleClose"
    >
      <Hyheader
        ref="formElement"
        :header-items="headerItems"
        :header-form="subFormData"
        :inline="false"
        :rules="rules"
        label-width="180px"
      >
        <template #submit>
          <div class="headerFlex">
            <el-button
              type="primary"
              @click="onSubmit"
            >
              保存
            </el-button>
          </div>
        </template>
      </Hyheader>
    </el-dialog>
  </Layout>
</template>

<script>
import { commonRequestGet, commonRequestPost, commonRequestPut } from '@/api/commonApi.js'
// import { cloneDeep } from 'lodash'
import { intersection } from 'lodash'

export default {
  data () {
    const validateBlackList = (rule, value, callback) => {
      console.log(this.subFormData.white_list_countries)
      if (value && this.subFormData.white_list_countries) {
        const len = intersection(value.split(','), this.subFormData.white_list_countries.split(','))
        if (len.length) {
          callback(new Error(`国家黑名单与国家白订单数据冲突:${len.join(',')}`))
        } else {
          callback()
        }
      } else {
        callback()
      }
    }
    const validateWhiteList = (rule, value, callback) => {
      if (value && this.subFormData.black_list_countries) {
        const len = intersection(value.split(','), this.subFormData.black_list_countries.split(','))
        if (len.length) {
          callback(new Error(`国家黑名单与国家白订单数据冲突:${len.join(',')}`))
        } else {
          callback()
        }
      } else {
        callback()
      }
    }
    return {
      tableData: [],
      formData: {
        page: 1,
        pageSize: 20
      },
      listInfo: {
        total: 0,
        loading: false,
        yesterdayUserCount: 0
      },
      subFormData: {
        id: '',
        goods_type: 2,
        app_id: '',
        app_name: '',
        black_list_countries: '',
        charge_text: '',
        charge_url: '',
        subscription_text: '',
        subscription_url: '',
        download_link: '',
        rank: 0,
        remark: '',
        status: 0,
        version: '',
        white_list_countries: ''
      },
      dialogVisible: false,
      formItemProp: [
        {
          label: '排序',
          prop: 'rank'
        },

        {
          label: '应用名称',
          prop: 'app_name'
        },
        {
          label: '包名/Bundle Id/App Key',
          prop: 'app_id'
        },
        {
          label: 'B面版本号',
          prop: 'version'
        },
        {
          label: '审核状态',
          prop: 'status',
          validate: 'select',
          optionChildren: [
            {
              value: '开启',
              key: 1
            },
            {
              value: '关闭',
              key: 0
            }
          ]
        },
        {
          label: '修改时间（UTC 8）',
          prop: 'operation_time'
        },
        {
          label: '修改人',
          prop: 'operator',
          formatter: (row) => row?.operator?.username
        },
        {
          label: '备注',
          prop: 'remark'
        },
        // {
        //   label: 'appkey',
        //   prop: 'app_id'
        // },
        {
          label: '下载地址',
          prop: 'download_link'
        },
        {
          label: '操作',
          // propsName: 'option',
          validate: 'template'
        }
      ],
      rules: {
        app_name: [{ required: true, message: '必填', trigger: 'blur' }],
        app_id: [{ required: true, message: '必填', trigger: 'blur' }],
        version: [{ required: true, message: '必填', trigger: 'blur' }],
        status: [{ required: true, message: '必填', trigger: 'blur' }],
        black_list_countries: [{ validator: validateBlackList, trigger: 'blur' }],
        white_list_countries: [{ validator: validateWhiteList, trigger: 'blur' }]
      }
    }
  },
  computed: {
    headerItems () {
      return [
        {
          label: '应用名称',
          value: 'app_name'
        },
        {
          label: '包名/Bundel id/App Key',
          value: 'app_id',
          disabled: !!this.subFormData.id
        },
        {
          label: 'B面版本号',
          value: 'version',
          tooltip: '<span style="color:#7a7a7a;font-size:14px;"><i class="el-icon-warning-outline" style="margin-right:5px;"></i>小于等于此版本号的才会显示B面</span>',
          style: 'width:250px;margin-right:10px'
        },
        {
          label: '审核状态',
          value: 'status',
          style: 'width:250px;',
          children: [
            {
              value: '开启',
              key: 1
            },
            {
              value: '关闭',
              key: 0
            }
          ]
        },
        // {
        //   label: '',
        //   value: 'operation_time'
        // },
        {
          label: '充值入口文案',
          value: 'charge_text'
        },
        {
          label: '充值入口链接',
          value: 'charge_url'
        },
        {
          label: '订阅入口文案',
          value: 'subscription_text'
        },
        {
          label: '订阅入口链接',
          value: 'subscription_url'
        },
        {
          label: '国家黑名单',
          value: 'black_list_countries',
          tooltip: '<span style="color:#7a7a7a;font-size:14px;"><i class="el-icon-warning-outline" style="margin-right:5px;"></i>国家缩写，多个国家代码之间用英文逗号“,”隔开</span>',
          style: 'width:250px;margin-right:10px'
        },
        {
          label: '国家白名单',
          value: 'white_list_countries',
          tooltip: '<span style="color:#7a7a7a;font-size:14px;"><i class="el-icon-warning-outline" style="margin-right:5px;"></i>国家缩写，多个国家代码之间用英文逗号“,”隔开</span>',
          style: 'width:250px;margin-right:10px'
        },
        {
          label: '下载地址',
          value: 'download_link'
        },
        {
          label: '备注',
          value: 'remark'
        },
        {
          label: '排序',
          value: 'rank'
        }
      ]
    }
  },
  created () {
    this.getList()
  },
  methods: {
    async getList () {
      try {
        this.listInfo.loading = true
        const res = await commonRequestGet('admin/app-review', this.formData)
        this.tableData = res.data
        this.listInfo.loading = false
        this.listInfo.total = res.total
      } catch (err) {
        console.log(err)
        this.listInfo.loading = false
      }
    },

    EditItem (row) {
      if (row) {
        for (const key in this.subFormData) {
          if (Object.hasOwnProperty.call(row, key)) {
            this.subFormData[key] = row[key]
          }
        }
        // this.subFormData = cloneDeep(row)
      }
      this.dialogVisible = true
    },
    onSubmit () {
      const form = this.$refs.formElement
      form.$refs.formRef.validate(async (valid) => {
        if (valid) {
          try {
            // 8de2eba1c04cd41b7425707948b722df
            if (this.subFormData.id) {
              await commonRequestPut(`admin/app-review/${this.subFormData.id}`, this.subFormData)
            } else {
              await commonRequestPost('admin/app-review', this.subFormData)
            }
            this.dialogVisible = false
            this.$message.success('保存成功')

            // this.listInfo.loading = false
            this.getList()
          } catch (error) {
            this.$message.error(error.response.data.message)
          }
        }
      })
    },
    handleClose () {
      this.subFormData = {
        id: '',
        goods_type: 2,
        app_id: '',
        app_name: '',
        black_list_countries: '',
        charge_text: '',
        charge_url: '',
        subscription_text: '',
        subscription_url: '',
        download_link: '',
        rank: 0,
        remark: '',
        status: 0,
        version: '',
        white_list_countries: ''
      }
      this.$refs.formElement.$refs.formRef.resetFields()
      this.dialogVisible = false
    }
  }
}
</script>

<style lang="less" scoped></style>
